$(document).ready(function () {
  $(".location i").addClass("brinca-brinca");
  $("body").css("display", "block");
  $("body").addClass("fadeTofade");
  // detect navbar position
  var domain = "https://haciendavillarrealcs.com/";
  var home_ = domain;
  var about_ = domain + "about";
  var menu_ = domain + "menu";
  var specials_ = domain + "specials";
  var catering_ = domain + "catering";
  var parties_ = domain + "parties";
  var jobs_ = domain + "jobs";
  var accessibility_ = domain + "accessibility";
  var reviews_ = domain + "revs";
  const pages = [
    { page: "home", file: home_ },
    { page: "about", file: about_ },
    { page: "menu", file: menu_ },
    { page: "specials", file: specials_ },
    { page: "catering", file: catering_ },
    { page: "parties", file: parties_ },
    { page: "jobs", file: jobs_ },
    { page: "accessibility", file: accessibility_ },
  ];

  $(
    ".home, .about, .menu, .specials, .catering, .parties, .jobs, .accessibility"
  ).each(function () {
    $(this).click(function () {
      var clickedClass = $(this).attr("class").split(" ")[0];
      var pageObj = pages.find(function (page) {
        return page.page === clickedClass;
      });
      if (pageObj) {
        var file = pageObj.file;
        window.location.href = file;
      }
    });
  });

  //Add value to google reviews iframe
  $("#reviewsIframe").attr("src", reviews_);

  //Add nav activo
  function agregarClaseNavActivo() {
    var currentPath = window.location.pathname;
    $(".page").each(function () {
      var link = $(this).text().toLowerCase();
      if (currentPath.includes(link)) {
        $(this).addClass("nav_activo");
      }
    });
    if (currentPath.endsWith("/")) {
      $(".home").addClass("nav_activo");
    }
  }
  $(document).ready(function () {
    agregarClaseNavActivo();
  });

  // Add class on scroll to button up
  $(window).on("scroll", function () {
    if ($(window).scrollTop() > 50) {
      $("header").addClass("sticky_header");
      $(".up").addClass("show_btn");
      $(".opn-cls span").addClass("achica");
    } else {
      $("header").removeClass("sticky_header");
      $(".up").removeClass("show_btn");
      $(".opn-cls span").removeClass("achica");
    }
  });

  // Button Up
  var upUp = $(".up");
  upUp.on("click", function () {
    $(window).scrollTop(0);
  });

  // Cuando se hace clic en '.opn-cls-orders'
  $(".opn-cls-orders").on("click", function (event) {
    // Evitar que el clic se propague al documento
    event.stopPropagation();
    // Alternar la clase 'expande' en '.nav-box'
    $(".nav-box").toggleClass("expande");
    $(".opn-cls-orders i").toggleClass("rotate-it");
  });

  // Cuando se hace clic en cualquier lugar del documento
  $(document).on("click", function (event) {
    // Verificar si el clic no ocurrió dentro de '.nav-box' o '.opn-cls-orders'
    if (
      !$(event.target).closest(".nav-box").length &&
      !$(event.target).closest(".opn-cls-orders").length
    ) {
      // Si no ocurrió dentro de ninguno de los elementos, cerrar la '.nav-box'
      $(".nav-box").removeClass("expande");
      $(".opn-cls-orders i").removeClass("rotate-it");
    }
  });

  //Funcion para manipular el navbar en mobile - pantallas chicas
  $(".opn-cls").on("click", function () {
    $("nav ul").toggleClass("open--cls");
    $(".opn-cls span").toggleClass("equis");
  });

  //Funcion para manipular si se da click por fuera del elemento
  $(document).on("click", function (event) {
    // Verificar si el clic fue fuera del menú y si el menú está abierto
    if (
      !$(event.target).closest("nav").length &&
      $("nav ul").hasClass("open--cls")
    ) {
      $("nav ul").removeClass("open--cls");
      $(".opn-cls span").removeClass("equis");
    }
  });

  //Quitar data en url para slider galeria
  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  //console.log('Ubicación: ' + currentPath);
  if (allowedPaths.includes(currentPath)) {
    $('[data-fancybox="gallery"]').fancybox({
      hash: false,
    });

    //Function to control the Slider
    $(document).ready(function () {
      var $slides, interval, $selectors, $btns, currentIndex, nextIndex;

      var cycle = function (index) {
        var $currentSlide, $nextSlide, $currentSelector, $nextSelector;

        nextIndex = index !== undefined ? index : nextIndex;

        $currentSlide = $($slides.get(currentIndex));
        $currentSelector = $($selectors.get(currentIndex));

        $nextSlide = $($slides.get(nextIndex));
        $nextSelector = $($selectors.get(nextIndex));

        $currentSlide.removeClass("active").css("z-index", "0");

        $nextSlide.addClass("active").css("z-index", "1");

        $currentSelector.removeClass("current");
        $nextSelector.addClass("current");

        currentIndex =
          index !== undefined
            ? nextIndex
            : currentIndex < $slides.length - 1
            ? currentIndex + 1
            : 0;

        nextIndex = currentIndex + 1 < $slides.length ? currentIndex + 1 : 0;
      };

      $(function () {
        currentIndex = 0;
        nextIndex = 1;

        $slides = $(".slide");
        $selectors = $(".selector");
        $btns = $(".btn");

        $slides.first().addClass("active");
        $selectors.first().addClass("current");

        interval = window.setInterval(cycle, 4000);

        $selectors.on("click", function (e) {
          var target = $selectors.index(e.target);
          if (target !== currentIndex) {
            window.clearInterval(interval);
            cycle(target);
            interval = window.setInterval(cycle, 4000);
          }
        });

        $btns.on("click", function (e) {
          window.clearInterval(interval);
          if ($(e.target).hasClass("prev")) {
            var target =
              currentIndex > 0 ? currentIndex - 1 : $slides.length - 1;
            cycle(target);
          } else if ($(e.target).hasClass("next")) {
            cycle();
          }
          interval = window.setInterval(cycle, 4000);
        });
      });

      var menu = [];
      $(".swiper-slide").each(function (index) {
        menu.push($(this).find(".slide-inner").attr("data-text"));
      });

      var interleaveOffset = 0.5;
      var swiperOptions = {
        loop: true,
        speed: 1000,
        parallax: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loopAdditionalSlides: 0,
        watchSlidesProgress: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          progress: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              var slideProgress = swiper.slides[i].progress;
              var innerOffset = swiper.width * interleaveOffset;
              var innerTranslate = slideProgress * innerOffset;
              swiper.slides[i].querySelector(".slide-inner").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
            }
          },
          touchStart: function () {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = "";
            }
          },
          setTransition: function (speed) {
            var swiper = this;
            for (var i = 0; i < swiper.slides.length; i++) {
              swiper.slides[i].style.transition = speed + "ms";
              swiper.slides[i].querySelector(".slide-inner").style.transition =
                speed + "ms";
            }
          },
        },
      };

      var swiper = new Swiper(".swiper-container", swiperOptions);

      var sliderBgSetting = $(".slide-bg-image");
      sliderBgSetting.each(function (indx) {
        if ($(this).attr("data-background")) {
          $(this).css(
            "background-image",
            "url(" + $(this).data("background") + ")"
          );
        }
      });
    });
  }

  // Navbar on menu
  function menuTabs() {
    var tabs = $(".menu_tabs");
    var menuDivs = $(".mm");
    menuDivs.hide();
    menuDivs.eq(0).show();
    tabs.eq(0).addClass("colorea");
    tabs.each(function (index) {
      $(this).click(function () {
        menuDivs.hide();
        menuDivs.eq(index).show();
        tabs.removeClass("colorea");
        $(this).addClass("colorea");
      });
    });
  }
  menuTabs();

  // Data scroll to - scroll function
  var allowedPaths = ["/home", "/", "/about"];
  var currentPath = window.location.pathname.replace(".html", "");
  console.log("Ubicación: " + currentPath);
  if (allowedPaths.includes(currentPath)) {
    //Data scroll to que mande al article.vips
    $(document).ready(function () {
      // Función para hacer scroll hacia el elemento con la clase .vips
      function scrollToVips() {
        var vipsElement = $(".vips");
        if (vipsElement.length > 0) {
          $("html, body").animate(
            {
              scrollTop: vipsElement.offset().top,
            },
            500
          ); // Puedes ajustar la duración de la animación según tus preferencias
        }
      }
      // Asociar la función al evento de clic en elementos con el atributo data-scroll-to
      $("[data-scroll-to]").on("click", function () {
        scrollToVips();
      });
    });
  }
  //About and home - fade from entrance
  var allowedPaths = ["/home", "/about", "/"];
  var currentPath = window.location.pathname.replace(".html", "");
  if (allowedPaths.includes(currentPath)) {
    //Fade from function
    $(document).ready(function () {
      const $fadersLeft = $(".fade-left");
      const $fadersRight = $(".fade-right");

      const appearOptions = {
        threshold: 0,
        rootMargin: "0px 0px -50px 0px",
      };

      const appearOnScroll = new IntersectionObserver(function (
        entries,
        appearOnScroll
      ) {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            $(entry.target).addClass("show");
          } else {
            $(entry.target).removeClass("show");
          }
        });
      },
      appearOptions);

      $fadersLeft.each(function () {
        appearOnScroll.observe(this);
      });

      $fadersRight.each(function () {
        appearOnScroll.observe(this);
      });
    });
  }

  // Mostrar modal en la página de inicio después de 2.5 segundos
  var allowedPaths = ["/home", "/"];
  var currentPath = window.location.pathname.replace(".html", "");

  // Esperar 2.5 segundos después de que la página se haya cargado
  setTimeout(function () {
    if (allowedPaths.includes(currentPath)) {
      // Cargar la imagen de fondo diferida
      var modalFondo = document.querySelector(".modal-fondo");
      var bgImage = modalFondo.getAttribute("data-bg");

      if (bgImage) {
        modalFondo.style.backgroundImage = bgImage;
      }

      // Agregar la propiedad visibility: visible al body
      document.body.classList.add("no-scroll");
      var modal = document.querySelector(".modal");
      modal.classList.add("show_modal");
      modal.classList.add("fadeIn");

      // Cerrar el modal
      document.querySelectorAll(".btn_modal").forEach(function (button) {
        button.addEventListener("click", function () {
          modal.classList.add("animationless");
          modal.classList.remove("fadeIn");
          document.body.classList.remove("no-scroll");
        });
      });
    }
  }, 1500); // 2500 milisegundos (2.5 segundos)

  //Get Full Year
  $(".year").each(function () {
    var currentDate = new Date();
    var ANHO = currentDate.getFullYear();
    $(this).text(ANHO);
  });

  var currentDate = new Date().toISOString();
  var ogUpdatedTimeMeta = $("#ogUpdatedTime");
  if (ogUpdatedTimeMeta.length > 0) {
    ogUpdatedTimeMeta.attr("content", currentDate);
  }

  $(document).ready(function () {
    // Selecciona todas las imágenes en el documento
    $("img").each(function () {
      // Obtén la URL actual de la imagen
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src + "?v=" + new Date().getTime());
    });
  });

  $(document).ready(function () {
    // Selecciona todos los elementos <script> que cargan archivos JavaScript
    $("script[src]").each(function () {
      // Obtén la URL actual del archivo JavaScript
      var src = $(this).attr("src");

      // Añade un query string con un timestamp para evitar la caché
      $(this).attr("src", src.split("?")[0] + "?v=" + new Date().getTime());
    });
  });

  //Location Name
  LOCATION_NAME = "hacienda villarreal";
  //Description
  ABOUT_HOME =
    "<span>Hacienda Villarreal is a family-owned and family-run Mexican Restaurant in Colorado Springs, Colorado. We serve authentic Mexican dishes made with fresh, delicious ingredients. Our menu features House Specialties you won’t find anywhere else in town.</span><span>Also, we have daily lunch and dinner specials, and the best happy hour deals in town! We are passionate about serving great food to our community and providing excellent ...</span>";
  ABOUT_ABOUT =
    "<span>Hacienda Villarreal is a family-owned and family-run Mexican Restaurant in Colorado Springs, Colorado. We serve authentic Mexican dishes made with fresh, delicious ingredients. Our menu features House Specialties you won’t find anywhere else in town.</span><span>Also, we have daily lunch and dinner specials, and the best happy hour deals in town! We are passionate about serving great food to our community and providing excellent service to our patrons. So, stop in today and enjoy a delicious meal you won’t soon forget! We can’t wait to serve you.</span>";
  //Address
  ADDRESS = "460 N Murray Blvd, Colorado Springs";
  ADDRESS_URL = "https://maps.app.goo.gl/jeSCtmGJuWuiui3fA";
  //Email
  EMAIL = "alejandrochilel1@icloud.com";
  //Phone
  PHONE_NUMBER = "(719) 418-5857";
  //Hours & Days
  D1 = "mon - sun";
  H1 = "9:00 a.m. - 9:00 p.m.";
  D2 = "";
  H2 = "";
  D3 = "";
  H3 = "";
  D4 = "";
  H4 = "";
  GOOGLE_DESK =
    "https://www.google.com/search?client=opera-gx&q=hacienda+villarreal+colorado&sourceid=opera&ie=UTF-8&oe=UTF-8#lrd=0x8713467edf2ac1dd:0x3003516939fe3d2,3,,,,";
  GOOGLE_MOB =
    "https://www.google.com/search?client=ms-android-attmexico-mx-rvc3&sca_esv=289b83ef89ab5e90&sca_upv=1&sxsrf=ADLYWIJsxFnbXlw6iCmPS_yXOjNGKuxKTw%3A1720553134209&q=Hacienda%20Villarreal&ludocid=216231153196065746&ibp=gwp%3B0%2C7&lsig=AB86z5VqzEYCUiw-x9ZVHtRjuHyQ&kgs=f42b57d5108ec075&shndl=-1&shem=labmtre%2Clsp&source=sh%2Fx%2Floc%2Fact%2Fm4%2F2#lkt=LocalPoiReviews&wptab=si:ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7iHiJaEUoFdpKeqUw9SalCB4aFPRHfdqxQmuujR71J_McPl4Omh-V37eYzKBnOAjBIHRUCwn-E7PqCex3FldcgP3SkOQKyeu8nuKQsli2eeUU_-AXaeCLaFAc3MEbchlRhPk2r4%3D&lpg=cid:CgIgAQ%3D%3D";
  //Orders Online
  PICK_UP = "https://www.clover.com/online-ordering/haciendamexicanfood";
  DELIVERY1 = "";
  DELIVERY2 = "";
  DELIVERY3 = "";
  //Iframe
  IFRAME =
    '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12430.916021383491!2d-104.748955!3d38.8386667!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8713467edf2ac1dd%3A0x3003516939fe3d2!2sHacienda%20Villarreal!5e0!3m2!1ses-419!2smx!4v1720552889099!5m2!1ses-419!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
  DESC01 = "order your favorite dishes in seconds!";
  DESC02 = "subscribe to <br> our vip club!";
  //Social Media
  FACEBOOK = "https://www.facebook.com/profile.php?id=100063528190470";
  INSTAGRAM = "https://www.instagram.com/haciendavillarreal__/";
  YELP = "";
  TIKTOK = "";
  //Agency
  RESTAURANTLY = "https://restaurantly.io/";
  //Agency
  $(".agency").on("click", function () {
    window.open(RESTAURANTLY, "_blank");
  });
  //Logo
  $("header img").css("cursor", "pointer");
  $("header img").on("click", function () {
    window.location.href = domain;
  });
  //Location - Name
  $(".location_name").each(function () {
    $(this).text(LOCATION_NAME);
  });
  //Location - Address
  $(".address").each(function () {
    $(this).text(ADDRESS);
  });
  $(".address-link").each(function () {
    $(this).click(function () {
      window.open(ADDRESS_URL, "_blank");
    });
  });
  //About Us
  $(".description_home").each(function () {
    $(this).html(ABOUT_HOME);
  });
  $(".description_about").each(function () {
    $(this).html(ABOUT_ABOUT);
  });
  //Phone
  $(".phone").each(function () {
    $(this)
      .text(PHONE_NUMBER)
      .click(function () {
        window.location.href = "tel:" + PHONE_NUMBER;
      });
  });
  // Email
  $(".email").each(function () {
    $(this)
      .text(EMAIL)
      .click(function () {
        window.location.href = "mailto:" + EMAIL;
      });
  });
  //Hours
  $(".d1").each(function () {
    $(this).text(D1);
  });
  $(".h1").each(function () {
    $(this).text(H1);
  });
  $(".d2").each(function () {
    $(this).text(D2);
  });
  $(".h2").each(function () {
    $(this).text(H2);
  });
  $(".d3").each(function () {
    $(this).text(D3);
  });
  $(".h3").each(function () {
    $(this).text(H3);
  });
  $(".d4").each(function () {
    $(this).text(D4);
  });
  $(".h4").each(function () {
    $(this).text(H4);
  });
  //Iframe
  $(".iframe").each(function () {
    $(this).html(IFRAME);
  });
  //Description Modal
  $(".description-01").each(function () {
    $(this).html(DESC01);
  });
  $(".description-02").each(function () {
    $(this).html(DESC02);
  });
  //Google Reviews
  $(".desk-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_DESK, "_blank");
    });
  });
  $(".mob-google").each(function () {
    $(this).click(function () {
      window.open(GOOGLE_MOB, "_blank");
    });
  });
  //3rd Party Vendors
  $(".order-pickup").each(function () {
    $(this).click(function () {
      window.open(PICK_UP, "_blank");
    });
  });
  $(".order-delivery1").each(function () {
    $(this).click(function () {
      window.open(DELIVERY1, "_blank");
    });
  });
  $(".order-delivery2").each(function () {
    $(this).click(function () {
      window.open(DELIVERY2, "_blank");
    });
  });
  $(".order-delivery3").each(function () {
    $(this).click(function () {
      window.open(DELIVERY3, "_blank");
    });
  });
  //Social Media
  $(".fb").each(function () {
    $(this).click(function () {
      window.open(FACEBOOK, "_blank");
    });
  });
  $(".ig").each(function () {
    $(this).click(function () {
      window.open(INSTAGRAM, "_blank");
    });
  });
  $(".yp").each(function () {
    $(this).click(function () {
      window.open(YELP, "_blank");
    });
  });
  $(".tk").each(function () {
    $(this).click(function () {
      window.open(TIKTOK, "_blank");
    });
  });
});
